import Preordering from "../../global/utils/Preordering";
import { Language } from "./language";
import { PayProviderName } from "./payProviders";

export enum CustomFieldType {
  Text = "text",
  Email = "email",
  Mobilenr = "mobilenr",
  Country = "country",
  Number = "number",
  Date = "date",
  Time = "time",
  DateTime = "selectpreorderingtime",
  Tip = "tip",
  Select = "select",
  PaymentMethodDropDown = "paymentmethoddropdown",
  PaymentMethodButtons = "paymentmethodbuttons",
  SelectButtons = "selectbuttons",
  // pager and pagernr are synonyms for the exact same behavior.
  Pager = "pager",
  PagerNr = "pagernr",
  OrderMode = "ordermode",
  Hidden = "hidden",
}

export interface OrderCustomField {
  customFieldName: string;
  value: any;
  orderCustomField?: OrderCustomField;
}

/**
 * customFields should not contain paymentCustomField
 */
export interface CustomFieldCollection {
  paymentCustomField?: CustomField;
  customFields: CustomField[];
}

export type TranslatableText = Partial<{
  [k in Language]: string;
}>;

export interface CustomFieldRule {
  field: string;
  operator: "=";
  value: string | number | boolean | null | undefined;
}

/**
 * @property label The label property
 * @property stepperLabel Kiosk-only, the text shown below the step in the checkout stepper
 * @property description This text is shown in the MUI element helperText property.
 * @property explanation Text shown to the user. Not part of MUI, just plain text as part of the custom field.
 *                       Not every customfield element implements the explanation.
 */
export default interface CustomField {
  type: CustomFieldType | string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  actief?: boolean;
  label?: string;
  remember?: boolean;
  descr?: string;
  description?: string;
  explanation?: TranslatableText | string;
  hide?: boolean;
  value?: any;
  defaultValue?: any;
  backgroundImage?: any;
  backgroundimage?: any;
  media_id?: number;
  maxlength?: number | string;
  rows?: number;
  pattern?: string;
  options?: CustomFieldOption[];
  preordering?: Preordering;
  styles?: any;
  customHtml?: any;
  customfieldType?: CustomfieldType;
  customfield_type?: CustomfieldType;
  stepperLabel?: TranslatableText | string;
  translationOverwrite?: TranslatableText | string;
  activeRule?: CustomFieldRule;
}

export type CustomfieldType = "order_only" | "payment_only" | undefined;

export interface CustomFieldOption {
  id: string | number;
  label: string;
  customFields?: CustomField[];
  toggleButtonStyles?: any;
  translationOverwrite?: any;
  payProvider?: PayProviderName;
  payMethod?: string | number;
  descr?: string;
}
