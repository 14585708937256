import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

import { appInitialized } from "../redux/globalSlice.tsx";
import { clearShoppingCart, orderSucceeded } from "../redux/shoppingCartSlice.tsx";
import OrderArticle from "../models/order/OrderArticle.ts";
import { kioskResetted } from "../redux/kioskSlice.tsx";

export type VoucherDefV2 = (
  | {
      id?: number;
      discountType: "AMOUNT_OFF_TOTAL";
      priceDiscountAmount: number;
      freeProducts_JSON: string[];
    }
  | {
      id?: number;
      discountType: "PERCENTAGE_OFF_TOTAL";
      priceDiscountPercentage: number;
      freeProducts_JSON: string[];
    }
  | {
      id?: number;
      discountType: "AMOUNT_OFF_PRODUCT";
      priceDiscountAmount: number;
      freeProducts_JSON: string[];
      includedProducts_JSON: string[];
      maxChooseItems: number;
    }
  | {
      id?: number;
      discountType: "PERCENTAGE_OFF_PRODUCT";
      priceDiscountPercentage: number;
      freeProducts_JSON: string[];
      includedProducts_JSON: string[];
      maxChooseItems: number;
    }
  | {
      id?: number;
      discountType: "ADD_DISCOUNTED_PRODUCT";
      freeProducts_JSON: string[];
    }
  | {
      id?: number;
      discountType: "COLLECTION";
    }
) & { active: 0 | 1; blocked: 0 | 1; title: string; minBasketPrice?: number };

export type VoucherV2 = {
  voucher: {
    code: string;
  };
  voucherdef: VoucherDefV2;
  orderArticles?: OrderArticle[];
  subVouchers?: VoucherV2[];
};

interface VoucherV2State {
  vouchers: VoucherV2[];
  addDiscountedProductVouchers: VoucherV2[];
}

const initState: VoucherV2State = {
  vouchers: [],
  addDiscountedProductVouchers: [],
};

function resetVouchersV2(state: Draft<VoucherV2State>) {
  state.vouchers = [];
  state.addDiscountedProductVouchers = [];
  localStorage.setItem("V5.vouchersV2.vouchers", JSON.stringify(state.vouchers));
}

export const vouchersV2Slice = createSlice({
  name: "vouchersV2",
  initialState: initState,
  reducers: {
    voucherV2Added: (state, action: PayloadAction<VoucherV2>) => {
      state.vouchers.push(action.payload);

      if (action.payload.voucherdef.discountType === "ADD_DISCOUNTED_PRODUCT") {
        state.addDiscountedProductVouchers.push(action.payload);
      }
      action.payload.subVouchers?.forEach((subVoucher) => {
        if (subVoucher.voucherdef.discountType === "ADD_DISCOUNTED_PRODUCT") {
          state.addDiscountedProductVouchers.push(subVoucher);
        }
      });

      localStorage.setItem("V5.vouchersV2.vouchers", JSON.stringify(state.vouchers));
    },
    voucherV2Removed: (state, action: PayloadAction<VoucherV2>) => {
      const indexToBeRemoved = state.vouchers.findIndex(
        (voucher) => voucher.voucher.code == action.payload.voucher.code
      );
      state.vouchers.splice(indexToBeRemoved, 1);
      localStorage.setItem("V5.vouchersV2.vouchers", JSON.stringify(state.vouchers));
    },
    voucherOrderArticleConfirmed: (state, action: PayloadAction<OrderArticle>) => {
      function findAddDiscountedVoucher(vouchers: VoucherV2[]): VoucherV2 | null {
        for (const voucher of vouchers) {
          if (
            voucher.voucherdef.discountType == "ADD_DISCOUNTED_PRODUCT" &&
            (!voucher.orderArticles || voucher.orderArticles.length === 0)
          ) {
            return voucher;
          } else if (voucher.subVouchers) {
            const found = findAddDiscountedVoucher(voucher.subVouchers);
            if (found) {
              return found;
            }
          }
        }
        return null;
      }

      const voucher = findAddDiscountedVoucher(state.vouchers);
      if (voucher) {
        if (!voucher.orderArticles) {
          voucher.orderArticles = [];
        }
        voucher.orderArticles.push(action.payload);
        state.addDiscountedProductVouchers.splice(0, 1);
      }
      localStorage.setItem("V5.vouchersV2.vouchers", JSON.stringify(state.vouchers));
    },
    vouchersDeclined: (state) => {
      resetVouchersV2(state);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(appInitialized, (state) => {
        const vouchersCartJson = localStorage.getItem("V5.vouchersV2.vouchers");
        let vouchersCart: VoucherV2[] = [];
        if (vouchersCartJson != null) {
          vouchersCart = JSON.parse(vouchersCartJson) as VoucherV2[];
        }
        state.vouchers = vouchersCart;
      })
      .addCase(orderSucceeded, (state) => {
        resetVouchersV2(state);
      })
      .addCase(kioskResetted, (state) => {
        resetVouchersV2(state);
      })
      .addCase(clearShoppingCart, (state) => {
        resetVouchersV2(state);
      });
  },
});

// Action creators are generated for each case reducer function
export const { voucherV2Added, voucherV2Removed, voucherOrderArticleConfirmed, vouchersDeclined } =
  vouchersV2Slice.actions;

export default vouchersV2Slice.reducer;
