import axios from "axios";
import { toast } from "react-toastify";
import { setSessionOutOfSync } from "../redux/globalSlice";
import store from "../redux/store";
import JamezzApi from "./JamezzApi";
import { createReturnPath } from "../config";
import { v4 as uuidv4 } from "uuid";
import { BlockUserInput } from "@jamezz/react-components";

type HttpOptions = Partial<{
  showSpinner: boolean;
  showToastSuccess: boolean;
  showToastError: boolean;
  toastSuccessMessage: string;
  toastErrorMessage: string;
}>;

export const DefaultOptions = {
  showSpinner: true,
  showToastSuccess: true,
  showToastError: true,
  toastSuccessMessage: "Success",
  toastErrorMessage: "Error",
};

export const DefaultOptionsGet = {
  showSpinner: false,
  showToastSuccess: false,
  showToastError: true,
  toastSuccessMessage: "",
  toastErrorMessage: "Error",
};

export function httpGet<T = unknown>(url: string, data: FormData | null = null, options: HttpOptions | null = null) {
  const o = { ...DefaultOptionsGet, ...options };

  const urlObject = new URL(window.location.origin + url);

  if (url !== JamezzApi.get.getSessionState.url) {
    urlObject.searchParams.set("session_mid", store.getState().global.webcode);
    urlObject.searchParams.set("session_return_path", window.location.origin + createReturnPath());
  }

  return httpRequest<T>("get", urlObject.pathname + urlObject.search, data, o);
}

export function httpPost<T = unknown>(
  url: string,
  data: FormData | object | null = null,
  options: HttpOptions | null = null
) {
  return httpRequest<T>("post", url, data, options);
}

export function httpRequest<T>(
  method: string,
  url: string,
  data: FormData | object | null = null,
  options: HttpOptions | null = null
) {
  return new Promise<T>((resolve, reject) => {
    const o = { ...DefaultOptions, ...options };
    doRequest(method, url, data, resolve, reject, o);
  });
}

export const doRequest = (
  method: string,
  url: string,
  data: FormData | object | null,
  resolve: ((value: any) => void) | null = null,
  reject: ((value: any) => void) | null = null,
  options: any | null = null,
  axiosConfig: any = null,
  setCancelToken: any = null
) => {
  method = method.toLowerCase();
  if (options == null) {
    options = DefaultOptions;
  }

  let loadingUuid = "";
  if (options.showSpinner) {
    loadingUuid = uuidv4();
    BlockUserInput.blockingStarted(loadingUuid);
  }

  if (data && method == "patch") {
    if (data instanceof FormData) {
      data.set("_method", "PATCH");
    } else {
      // @ts-ignore
      data["_method"] = "PATCH";
    }
  }
  if (!data) {
    data = new FormData();
  }
  if (data && url !== JamezzApi.get.getSessionState.url) {
    if (data instanceof FormData) {
      data.set("session_mid", store.getState().global.webcode);
      data.set("session_return_path", window.location.origin + createReturnPath());
      if (store.getState().shoppingCart.serverSideShoppingCart?.uuid) {
        data.set("shopping_cart_uuid", store.getState().shoppingCart.serverSideShoppingCart?.uuid ?? "");
      }
    } else {
      // @ts-ignore
      data.session_mid = store.getState().global.webcode;
      // @ts-ignore
      data.session_return_path = window.location.origin + createReturnPath();
      // @ts-ignore
      data.shopping_cart_uuid = store.getState().shoppingCart.serverSideShoppingCart?.uuid;
    }
  }

  const source = axios.CancelToken.source();
  if (setCancelToken) {
    axiosConfig = { ...axiosConfig, cancelToken: source.token };
    setCancelToken(source);
  }

  const uninterceptedAxiosInstance = axios.create(axiosConfig);
  // @ts-ignore
  return uninterceptedAxiosInstance({
    method: method == "patch" ? "post" : method,
    url,
    data,
    headers: { "Appv5-Build-Date": import.meta.env.__BUILD_DATE__, "Appv5-Build-Release": import.meta.env.__RELEASE__ },
  }) //, headers: { "Content-Type": "multipart/form-data" } })
    .then((response) => {
      if (response?.data?.message === "Session logged out! Please try again") {
        store.dispatch(setSessionOutOfSync());
      }

      if (response.data.status === "error") {
        if (options.showToastError) {
          toast.error(response.data.message);
        }
        if (reject) {
          reject(response);
        }
        return;
      }

      if (options.showToastSuccess) {
        toast.success(options.toastSuccessMessage);
      }
      if (resolve) {
        resolve(response);
      }
    })
    .catch((err) => {
      if (options.showToastError) {
        toast.error(options.toastErrorMessage);
      }
      if (reject) {
        reject(err);
      }
    })
    .finally(() => {
      if (loadingUuid) {
        BlockUserInput.blockingStopped(loadingUuid);
      }
    });
};
