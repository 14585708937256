import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import OptionGroup from "../../models/menu/OptionGroup";
import { selectArticlesMap } from "./selectArticlesMap";
import { selectArticlegroupArticlesMap } from "./selectArticlegroupArticlesMap";
import md5 from "md5";
import { ProductResponse } from "../api/menuDataApi";
import _ from "lodash";

export const selectOptionGroupsMap = createSelector(
  [
    (state: RootState) => state.menuData.option_groups,
    (state: RootState) => state.menuData.products,
    selectArticlesMap,
    selectArticlegroupArticlesMap,
  ],
  (optionGroups, products, articlesMap, articlegroupArticlesMap) => {
    const optionGroupsMap: Record<string, OptionGroup> = {};
    optionGroups.forEach((optionGroupResponse) => {
      let maxSelect = Number(optionGroupResponse.max_count);
      if (Number.isNaN(maxSelect)) {
        maxSelect = 0;
      }
      let minSelect = Number(optionGroupResponse.min_count);
      if (Number.isNaN(minSelect)) {
        if (maxSelect === 1) {
          minSelect = 1;
        } else {
          minSelect = 0;
        }
      }
      const optionIds: string[] = [];

      [...(optionGroupResponse.option_group_products ?? [])]
        .sort(
          (a, b) =>
            (a.sort_key ?? articlesMap[a.product_id]?.sortKey) - (b.sort_key ?? articlesMap[b.product_id]?.sortKey)
        )
        .forEach((optionGroupProduct: any) => {
          optionIds.push(optionGroupProduct.product_id);
        });

      optionGroupResponse.option_group_product_groups
        ?.reduce((productIds: string[], pivot: any) => {
          articlegroupArticlesMap[pivot.menukaart_id]?.forEach((productId) => {
            if (articlesMap[productId]) {
              productIds.push(productId);
            }
          });
          return productIds;
        }, [])
        ?.sort((a, b) => articlesMap[a]?.sortKey - articlesMap[b]?.sortKey)
        .forEach((productId: string) => {
          optionIds.push(String(productId));
        });

      const optionGroup: OptionGroup = {
        id: optionGroupResponse.id,
        name: optionGroupResponse.name,
        countable: optionGroupResponse.countable,
        minCount: minSelect,
        maxCount: maxSelect,
        skip: Boolean(optionGroupResponse.skip),
        optionIds: _.uniq(optionIds),
        showMaxNumberOfItems: optionGroupResponse.show_max_number_of_items ?? 0,
        isUpsell: false,
        updatedAt: "",
        sortKey: optionGroupResponse.sort_key,
        translations: optionGroupResponse.translations,
        default_counts: optionGroupResponse.default_counts,
      };

      optionGroupsMap[optionGroupResponse.id] = optionGroup;
    });

    const parseApiArticle = (apiArticle: ProductResponse) => {
      let opties = apiArticle.opties;
      if (typeof opties == "string") {
        opties = JSON.parse(opties);
      }
      if (opties && typeof opties == "object") {
        opties = Object.values(opties);
      }
      (opties ?? [])
        ?.filter(
          (apiOptionGroup: any) => (apiOptionGroup.is_active_in_pos ?? true) && !(apiOptionGroup.disabled ?? false)
        )
        .forEach((apiOptionGroup: any) => {
          const optionIds =
            apiOptionGroup.optie
              ?.filter(
                (apiOptionItem: any) =>
                  (apiOptionItem.actief ?? true) &&
                  (apiOptionItem.is_active_in_pos ?? true) &&
                  !(apiOptionItem.disabled ?? false)
              )
              .map((apiOptionItem: any) => {
                return String(apiOptionItem.id ?? md5(JSON.stringify(apiOptionItem)));
              }) ?? [];

          const optionGroupId = String(apiOptionGroup.id ?? md5(JSON.stringify(apiOptionGroup)));
          let maxSelect = Number(apiOptionGroup.maxselect ?? apiOptionGroup.max_count);
          if (Number.isNaN(maxSelect)) {
            maxSelect = 0;
          }
          let minSelect = Number(apiOptionGroup.minselect ?? apiOptionGroup.min_count);
          if (Number.isNaN(minSelect)) {
            if (maxSelect === 1) {
              minSelect = 1;
            } else {
              minSelect = 0;
            }
          }

          const optionGroup: OptionGroup = {
            id: optionGroupId,
            name: apiOptionGroup.naam ?? apiOptionGroup.name,
            countable: apiOptionGroup.countable ?? false,
            minCount: minSelect,
            maxCount: maxSelect,
            optionIds: optionIds,
            showMaxNumberOfItems: apiOptionGroup.show_max_number_of_items ?? 0,
            isUpsell: false,
            skip: apiOptionGroup.skip ?? false,
            updatedAt: "",
            sortKey: apiOptionGroup.sort_key,
            translations: apiOptionGroup.translations,
            default_counts: { product_ids: {} },
          };
          optionGroupsMap[optionGroup.id] = optionGroup;
        });

      if (apiArticle.freeOptionsRequired && apiArticle.freeOptionsRequired !== "[]") {
        // optionGroupIds.push(
        const optionGroup = parseUpsell(
          apiArticle,
          apiArticle.freeOptionsRequiredTitle,
          JSON.parse(apiArticle.freeOptionsRequired),
          true,
          1
        );
        // );

        optionGroupsMap[optionGroup.id] = optionGroup;
      }
      if (apiArticle.freeOptionsRequired2 && apiArticle.freeOptionsRequired2 !== "[]") {
        // optionGroupIds.push(
        const optionGroup = parseUpsell(
          apiArticle,
          apiArticle.freeOptionsRequiredTitle2,
          JSON.parse(apiArticle.freeOptionsRequired2),
          true,
          2
        );
        // );
        optionGroupsMap[optionGroup.id] = optionGroup;
      }
      if (apiArticle.freeOptionsRequired3 && apiArticle.freeOptionsRequired3 !== "[]") {
        // optionGroupIds.push(
        const optionGroup = parseUpsell(
          apiArticle,
          apiArticle.freeOptionsRequiredTitle3,
          JSON.parse(apiArticle.freeOptionsRequired3),
          true,
          3
        );
        // );
        optionGroupsMap[optionGroup.id] = optionGroup;
      }
    };

    products.forEach(parseApiArticle);

    return optionGroupsMap;
  }
);

function parseUpsell(
  apiArticle: ProductResponse,
  title: string = "",
  ids: number[],
  isRequired: boolean,
  sortKey: number
) {
  const apiOptionGroup = {
    naam: title ?? "",
    maxselect: isRequired ? 1 : 0,
    minselect: isRequired ? 1 : 0,
    ids: ids.map((id) => String(id)),
    id: apiArticle.id,
  };

  const optionGroupId = md5(JSON.stringify(apiOptionGroup));

  let maxSelect = Number(apiOptionGroup.maxselect);
  if (Number.isNaN(maxSelect)) {
    maxSelect = 0;
  }
  let minSelect = Number(apiOptionGroup.minselect);
  if (Number.isNaN(minSelect)) {
    if (maxSelect === 1) {
      minSelect = 1;
    } else {
      minSelect = 0;
    }
  }

  const optionGroup: OptionGroup = {
    id: optionGroupId,
    name: apiOptionGroup.naam,
    countable: false,
    minCount: minSelect,
    maxCount: maxSelect,
    optionIds: apiOptionGroup.ids ?? [],
    showMaxNumberOfItems: 0,
    isUpsell: true,
    skip: false,
    updatedAt: "",
    sortKey: sortKey,
    translations: undefined,
    default_counts: { product_ids: {} },
  };

  return optionGroup;
}
